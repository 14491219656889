<template>
  <div class="case_data">
    <div class="row">
      <div>
        <span class="span">共{{ total }}条案例数据</span>
      </div>
      <div class="search_form">
        <el-form label-width="100px" inline>
          <el-form-item label="所在地区：">
            <city-no-dis
              ref="child"
              :province="queryInfo.province"
              :city="queryInfo.city"
              @searchProvince="searchProvince"
              @searchCity="searchCity"
            />
            <!-- <city-select -->
            <!-- ref="child" -->
            <!-- :province="queryInfo.province" -->
            <!-- :city="queryInfo.city" -->
            <!-- :district="queryInfo.district" -->
            <!-- @searchProvince="searchProvince" -->
            <!-- @searchCity="searchCity" -->
            <!-- @searchDistrict="searchDistrict" -->
            <!-- /> -->
          </el-form-item>
          <el-form-item label="案例类型：">
            <el-select v-model="queryInfo.state" @change="search" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.text"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="案例关注：">
            <el-select v-model="queryInfo.isInterested" @change="search" placeholder="请选择">
              <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.text"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="search_right">
        <el-input
          placeholder="请输入案例标题"
          v-model="queryInfo.informationName"
          class="input-with-select"
          clearable
          @change="search"
          style="width: 320px"
        >
          <el-button slot="append" @click="search">搜索</el-button>
        </el-input>
      </div>
    </div>
    <div class="home_context_flex_box">
      <div class="box_item" v-for="(item, index) in dataList" :key="index">
        <div class="box_item_left">
          <img :src="item.coverImages" alt="" />
          <div class="box_item_left_text" v-if="item.state && item.state == '本行业'">
            {{ item.state }}
          </div>
          <div class="box_item_left_text hy" v-else-if="item.state">
            {{ item.state }}
          </div>
        </div>
        <div class="box_item_center">
          <h3>{{ item.informationName }}</h3>
          <div class="box_item_center_text">
            <div class="location">{{ item.province }} - {{ item.city }}</div>
            <div class="degree">
              <span>案例匹配度：</span>
              <el-progress
                type="circle"
                width="30"
                :show-text="false"
                :stroke-width="4"
                :percentage="item.correlation"
              ></el-progress>
              <div class="container" style="color: #3785e4; margin-left: 5px">
                {{ item.correlation || 0 }} %
              </div>
            </div>
          </div>
          <div class="box_item_center_nr">{{ item.briefIntroduction }}</div>
        </div>
        <div class="box_item_right">
          <div class="top">
            <div class="no-interest" v-if="item.isInterested == false">
              <img src="../../../assets/image/case_data/no-interest.png" />
              用户不关注
            </div>
            <div class="interest" v-else>
              <img src="../../../assets/image/case_data/interest.png" />
              用户关注
            </div>

            <span @click="edit(item)" style="margin-left: 40px; font-size: 16px">详情</span>
          </div>
          <div class="bottom">案例时间：{{ item.issueTime }}</div>
        </div>
      </div>
    </div>
    <div class="new_page">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <el-dialog
      title="案例数据详情"
      :visible.sync="DialogVisible"
      width="70%"
      :close-on-click-modal="false"
    >
      <div class="case_data_dialog" ref="pdfContent">
        <div class="compny_flex">
          <img src="../../../assets/image/case_data/Vector.png" alt="" />
          <span class="right">基本信息</span>
        </div>
        <div class="case_tabel">
          <div class="case_table_li">
            <div class="case_table_li_left">案例标题</div>
            <div class="case_table_li_right">{{ caseInfo.informationName }}</div>
          </div>
          <div class="case_table_li">
            <div class="case_table_li_left">案例图片</div>
            <div class="case_table_li_right">
              <div class="img" v-if="caseInfo.coverImages">
                <img
                  :src="item"
                  alt=""
                  v-for="(item, index) in caseInfo.coverImages.split(',')"
                  :key="index"
                />
              </div>
            </div>
          </div>
          <div class="case_table_li">
            <div class="case_table_li_left">所在地区</div>
            <div class="case_table_li_right">{{ caseInfo.province }}-{{ caseInfo.city }}</div>
          </div>
          <div class="case_table_li">
            <div class="left">
              <div class="case_table_li_left">案例类型</div>
              <div class="case_table_li_right">{{ caseInfo.state }}</div>
            </div>
            <div class="right">
              <div class="case_table_li_left">案例匹配度</div>
              <div class="case_table_li_right">
                <el-progress
                  type="circle"
                  width="30"
                  :show-text="false"
                  :stroke-width="4"
                  :percentage="caseInfo.correlation"
                ></el-progress>
                {{ caseInfo.correlation || 0 }} %
              </div>
            </div>
          </div>
          <div class="case_table_li">
            <div class="left">
              <div class="case_table_li_left">案例时间</div>
              <div class="case_table_li_right">{{ caseInfo.issueTime }}</div>
            </div>
            <div class="right">
              <div class="case_table_li_left">推送时间</div>
              <div class="case_table_li_right">{{ caseInfo.pushTime }}</div>
            </div>
          </div>
          <div class="case_table_li">
            <div class="case_table_li_left">案例简介</div>
            <div class="case_table_li_right" v-html="caseInfo.briefIntroduction"></div>
          </div>
        </div>

        <div class="compny_flex">
          <img src="../../../assets/image/case_data/tongdian.png" alt="" />
          <span class="right">客户痛点</span>
        </div>
        <div class="case_tabel">
          <div class="case_table_li">
            <div class="case_table_li_left">痛点描述</div>
            <div class="case_table_li_right" v-html="caseInfo.customerPainPoint"></div>
          </div>
        </div>
        <div class="compny_flex">
          <img src="../../../assets/image/case_data/fangan.png" alt="" />
          <span class="right">解决方案</span>
        </div>
        <div class="case_tabel">
          <div class="case_table_li">
            <div class="case_table_li_left">方案描述</div>
            <div class="case_table_li_right" v-html="caseInfo.customerSolution"></div>
          </div>
        </div>
        <div class="compny_flex">
          <img src="../../../assets/image/case_data/anli.png" alt="" />
          <span class="right">案例成果</span>
          <!-- <div class="download" @click="download()">
            <img src="../../../assets/image/case_data/download.png" alt="" />
            下载案例pdf
          </div> -->
        </div>
        <div class="case_tabel">
          <div class="case_table_li">
            <div class="case_table_li_left">成果描述</div>
            <div class="case_table_li_right" v-html="caseInfo.caseOutcome"></div>
          </div>
        </div>
        <div class="compny_flex">
          <img src="../../../assets/image/case_data/xinqu.png" alt="" />
          <span class="right">案例关注</span>
        </div>
        <div class="case_tabel">
          <div class="case_table_li">
            <div class="case_table_li_left">有无关注</div>
            <div class="case_table_li_right">
              <el-radio-group v-model="caseInfo.isInterested">
                <el-radio :label="true">
                  <img width="20" height="20" src="../../../assets/image/case_data/interest.png" />

                  用户关注</el-radio
                >
                <el-radio :label="false">
                  <img
                    width="20"
                    height="20"
                    src="../../../assets/image/case_data/no-interest.png"
                  />

                  用户不关注</el-radio
                >
              </el-radio-group>
            </div>
          </div>
        </div>
      </div>
      <div style="text-align: center; margin-top: 40px">
        <el-button type="primary" @click="downloadPDF">下载PDF</el-button>
        <el-button type="primary" @click="sumbitClick">保 存</el-button>
        <el-button @click="DialogVisible = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import CitySelect from '@/components/CitySelect.vue'
import { selectPlanPage, getPlanDetail, updatePlan } from '@/api/caseData.js'
import CityNoDis from './CityNoDis.vue'
const defalutQueryInfo = Object.freeze({
  district: '',
  pageNum: 1,
  pageSize: 10,
  city: '',
  companyId: 0,
  informationName: '',
  isInterested: '',
  province: '',
  state: ''
})
export default {
  name: 'caseData',
  props: {
    id: {
      // 公司id
      typeof: Number
    }
  },
  data() {
    return {
      caseInfo: {}, //案例详情数据
      dataList: [], // 案例数据列表
      total: 0, // 总条数
      DialogVisible: false, // 弹框显示
      options: [
        { text: '全部', value: '' },
        { text: '本行业', value: '本行业' },
        { text: '相似行业', value: '相似行业' }
      ], // 案例类型
      options1: [
        { text: '全部', value: '' },
        { text: '用户关注', value: true },
        { text: '用户不关注', value: false }
      ], // 案例兴趣
      queryInfo: { ...defalutQueryInfo } // 初始化查询条件
    }
  },
  components: {
    // CitySelect,
    CityNoDis
  },
  created() {
    this.search()
  },
  methods: {
    // 下载pdf
    downloadPDF() {
      const content = this.$refs.pdfContent
      html2canvas(content).then((canvas) => {
        const pdf = new jsPDF()
        const imgData = canvas.toDataURL('image/png')
        const imgProps = pdf.getImageProperties(imgData)
        const pdfWidth = pdf.internal.pageSize.getWidth()
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight)
        pdf.save(`${this.caseInfo.informationName}.pdf`)
      })
    },
    // 点击打开案例成功
    download() {
      if (this.caseInfo.caseOutcomeFile) {
        window.open(this.caseInfo.caseOutcomeFile)
      } else {
        this.$message.warning('暂无案例文件！')
      }
    },
    // 点击保存
    sumbitClick() {
      updatePlan(this.caseInfo).then((result) => {
        if (result.resultCode === 200) {
          this.$message.success('保存成功')
          this.DialogVisible = false
          this.search()
        }
      })
    },
    // 点击详情
    async edit(row) {
      const res = await getPlanDetail({ id: row.id })
      if (res.resultCode === 200) {
        this.caseInfo = res.data
        this.DialogVisible = true
      }
    },
    //返回省
    searchProvince(val) {
      this.queryInfo.province = val
      this.search()
    },
    //返回城市
    searchCity(val) {
      this.queryInfo.city = val
      this.search()
    },
    //返回区/县
    searchDistrict(val) {
      this.queryInfo.district = val
      this.search()
    },
    // 查询数据
    async search() {
      this.queryInfo.companyId = this.id
      const res = await selectPlanPage(this.queryInfo)
      if (res.resultCode === 200 && res.data) {
        this.total = res.data.total

        this.dataList = res.data.list
        this.dataList.forEach((el) => {
          el.briefIntroduction = this.removeLableAndImage(el.briefIntroduction)
        })
      }
    },
    removeLableAndImage(html) {
      let msg = html
        .replace(/<(p|div)[^>]*>(<br\/?>|&nbsp;)<\/\1>/gi, '\n')
        .replace(/<br\/?>/gi, '\n')
        .replace(/<[^>/]+>/g, '')
        .replace(/(\n)?<\/([^>]+)>/g, '')
        .replace(/\u00a0/g, ' ')
        .replace(/&nbsp;/g, ' ')
        .replace(/<\/?(img)[^>]*>/gi, '')
        .replace(/&amp;/g, '&')
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/&#39;/g, "'")
        .replace(/&quot;/g, '"')
        .replace(/<\/?.+?>/g, '')
      return msg
    },
    //监听pagesize变化
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.search()
    },
    //监听页码变化
    handleCurrentChange(newCurrent) {
      this.queryInfo.pageNum = newCurrent
      this.search()
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-radio__label {
  font-size: 14px;
  padding-left: 10px;
  display: flex;
  align-items: center;
}
::v-deep .el-progress {
  width: 32px !important;
  height: 32px !important;
}
::v-deep .el-radio-group {
  display: inline-block;
  line-height: 1;
  vertical-align: middle;
  font-size: 0;
  display: flex;
}
::v-deep .el-radio__inner {
  border: 1px solid #dcdfe6;
  border-radius: 100%;
  width: 18px;
  height: 18px;
  background-color: #fff;
  position: relative;
  cursor: pointer;
  display: inline-block;
  box-sizing: border-box;
}
::v-deep .el-dialog__body {
  padding: 0px 20px 30px 20px;
  color: #606266;
  font-size: 14px;
  word-break: break-all;
}
::v-deep .el-radio {
  color: #606266;
  font-weight: 500;
  line-height: 1;
  position: relative;
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;
  outline: 0;
  font-size: 14px;
  display: flex;
  margin-right: 30px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  img {
    margin-right: 10px;
  }
}
.case_data_dialog {
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  padding: 20px;

  .download {
    display: flex;
    margin-left: 10px;
    align-items: center;
    box-sizing: border-box;
    padding: 6px 10px;
    border-radius: 4px;
    border: 1px solid #4e93fb;
    color: #4e93fb;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;

    img {
      width: 20px !important;
      height: 20px !important;
    }
  }

  .compny_flex {
    padding: 12px 0px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e7e7e7;

    img {
      width: 30px;
      height: 30px;
    }
    .right_border {
      display: inline-block;
      width: 2px;
      height: 20px;
      background: #4e93fb;
    }
    .right {
      margin-left: 14px;
      color: #4e93fb;
      font-size: 16px;
    }
    .color_add {
      color: #448aff;
      margin-left: 10px;
    }
  }

  .case_tabel .case_table_li:nth-last-child(1) {
    border-bottom: 1px solid #dcdee0;
  }

  .case_tabel {
    width: 100%;
    overflow: hidden;
    font-size: 16px;
    margin-top: 20px;
    font-weight: 400;

    .case_table_li {
      width: 100%;
      display: flex;
      box-sizing: border-box;
      border-right: 1px solid #dcdee0;
      border-top: 1px solid #dcdee0;

      .left {
        width: 50%;
        display: flex;
        align-items: center;
        line-height: 80px;
        box-sizing: border-box;
      }

      .right {
        width: 50%;
        display: flex;
        align-items: center;
        line-height: 80px;
        box-sizing: border-box;
      }

      .case_table_li_left {
        width: 120px;
        background: #fafafa;
        display: flex;
        font-weight: 550;
        align-items: center;
        border-right: 1px solid #dcdee0;
        justify-content: center;
        border-left: 1px solid #dcdee0;
      }

      .case_table_li_right {
        flex: 1;
        display: flex;
        box-sizing: border-box;
        padding: 0 12px;
        align-items: center;
        line-height: 2em;
        min-height: 80px;
        flex-shrink: 1;
        .img {
          display: flex;
          align-items: center;
          img {
            width: 100px;
            height: 64px;
            border-radius: 8px;
            margin-right: 16px;
          }
        }
      }
    }
  }
}
.case_data {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: calc(100vh - 340px);
  padding: 20px 30px 10px 30px;
  box-sizing: border-box;
  background-color: #fff;

  .new_page {
    margin: auto 0;
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
    text-align: center;
  }

  .row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    justify-content: space-between;

    .span {
      color: #393939;
      font-size: 16px;
      font-weight: 400;
    }

    ::v-deep .el-form--inline .el-form-item {
      margin-bottom: 0 !important;
    }

    .search_form {
      display: flex;
      align-items: center;
    }

    .search_right {
      margin-right: 20px;
      ::v-deep .el-button {
        border: 1px solid #448aff;
        color: #fff;
        border-radius: 0;
        background-color: #448aff;
      }
    }
  }

  .home_context_flex_box {
    height: calc(100vh - 300px);
    overflow: auto;

    .box_item {
      width: 100%;
      box-sizing: border-box;
      padding: 16px;
      margin-top: 6px;
      margin-bottom: 14px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #d7d7d7;

      .box_item_left {
        width: 136px;
        height: 88px;
        position: relative;

        img {
          width: 136px;
          height: 88px;
          border-radius: 8px;
          position: absolute;
          left: 0;
          top: 0;
        }

        .box_item_left_text {
          position: absolute;
          width: 94px;
          height: 34px;
          left: -14px;
          top: -10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          font-size: 16px;
          font-weight: 400;
          background-size: 94px, 34px;
          background-repeat: no-repeat;
          background-image: url('../../../assets/image/case_data/Group 1000001619.png');
        }

        .hy {
          background-image: url('../../../assets/image/case_data/Group 1142814713.png');
        }
      }

      .box_item_center {
        flex: 1;
        overflow: hidden;
        margin: 0 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 88px;

        h3 {
          color: #151515;
          font-size: 18px;
          font-weight: bold;
          margin: 0;
        }

        .box_item_center_text {
          width: 100%;
          display: flex;
          align-items: center;

          .location {
            color: #333333;
            font-size: 14px;
            font-weight: 400;
            margin-right: 16px;
          }

          .degree {
            display: flex;
            align-items: center;
            span {
              color: #787b88;
              font-size: 14px;
              font-weight: 400;
            }

            .container {
              font-size: 12px;
            }
          }
        }

        .box_item_center_nr {
          width: 100%;
          color: #333333;
          font-size: 12px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }

      .box_item_right {
        width: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .top {
          width: 100%;
          align-items: center;
          justify-content: center;
          display: flex;
          .interest {
            display: flex;
            align-items: center;
            img {
              width: 20px;
              height: 18px;
              margin-right: 5px;
            }

            color: #ff4d4f;
            font-size: 16px;
            font-weight: 400;
          }

          .no-interest {
            display: flex;
            align-items: center;
            img {
              width: 20px;
              height: 18px;
              margin-right: 5px;
            }

            color: #00bdbd;
            font-size: 16px;
            font-weight: 400;
          }

          span {
            color: #448aff;
            font-size: 14px;
            font-weight: 400;
            cursor: pointer;
          }
        }

        .bottom {
          width: 100%;
          color: #999999;
          font-size: 12px;
          margin-top: 20px;
        }
      }
    }
  }
}
</style>
