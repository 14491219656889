<!-- 客户需求 -->
<template>
  <div class="contact_person">
    <div class="row">
      <div>
        <span>共{{total}}条建议</span>
      </div>
      <div class="search_right">
        <el-input
          placeholder="请输入建议名称"
          v-model="queryInfo.name"
          class="input-with-select"
          style="width: 440px"
        >
          <el-button slot="append" @click="search">搜索</el-button>
        </el-input>
      </div>
    </div>
    <div class="table">
      <el-table
        ref="contactPersonTable"
        :data="list"
        style="width: 100%"
        :header-cell-style="{ background: '#F8F9FB' }"
      >
        <el-table-column label="建议编号" min-width="100" align="center">
          <template v-slot="{ row }">
            <div>G00{{ row.id }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="suggestionDescribe" label="建议描述" min-width="100" align="center">
        </el-table-column>
        <el-table-column prop="behaviorData" label="企业行为" min-width="100" align="center">
        </el-table-column>
        <el-table-column prop="sceneData" label="应用场景" min-width="120" align="center">
        </el-table-column>
        <el-table-column prop="suggestionScore" label="建议评分" min-width="140" align="center" >
        </el-table-column>
        <el-table-column prop="createTime" label="推送时间" min-width="140" align="center" sortable>
        </el-table-column>

        <!-- <el-table-column label="操作" min-width="140" align="center">
          <template v-slot="{ row }">
            
          </template>
        </el-table-column> -->
      </el-table>
    </div>
    <div class="new_page">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import { selectPresalesSuggestionPage } from '@/api/enterpriseCustomer.js'

const defaultQueryInfo = Object.freeze({
  companyIds: [],
  customerId: 0,
  name: '',
  orderByParam: '',
  orgCompanyId: '',
  pageNum: 1,
  pageSize: 10
})

export default {
  name: 'CustomerDemand',
  components: {},
  props: {
    id: {
      type: Number
    },
    orgCompanyId:{
      type: String
    }
  },
  watch: {
    orgCompanyId: {
      handler(val) {
        if (val) {
          this.queryInfo.orgCompanyId=val
          this.search()
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      echo: 0, // 0是新增，1是编辑
      addDialogVisible: false, // 添加联络人弹窗
      queryInfo: { ...defaultQueryInfo }, // 联络人查询一览
      list: [], // 联络人列表
      total: 0 // 联络人总条数
    }
  },
  created() {
   
  },
  methods: {
    async search() {
     
      const res = await selectPresalesSuggestionPage(this.queryInfo)
      if (res.code === 200) {
        this.list = res.data.list
        this.total = res.data.total
      } else {
        this.$message.warning('获取联络人信息失败!')
      }
    },
    //监听pagesize变化
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.search()
    },
    //监听页码变化
    handleCurrentChange(newCurrent) {
      this.queryInfo.pageNum = newCurrent
      this.search()
    }
  }
}
</script>

<style lang="less" scoped>
.contact_person {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: calc(100vh - 340px);
  padding: 20px 30px 10px 30px;
  box-sizing: border-box;
  background-color: #fff;
  .table {
    height: calc(100vh - 390px);
    overflow-y: auto;
    margin-top: 10px;
    .span_btn_list {
      span {
        cursor: pointer;
        margin-right: 10px;
      }
    }
  }
}

.dialog_footer {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #e1e1e1;
  padding: 20px;
}
.new_page {
  margin: 10px auto;
  width: 100%;
  text-align: center;
}
.search_right {
  // width: 560px;
  margin-right: 20px;
  /deep/ .el-button {
    border: 1px solid #448aff;
    color: #fff;
    border-radius: 0;
    background-color: #448aff;
  }
}
.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.iconfont {
  margin-right: 5px;
}

.color1 {
  color: #448aff;
}
.color2 {
  color: #9b9a9a;
}
</style>