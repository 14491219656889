<!-- 联络人 -->
<template>
  <div class="contact_person">
    <div class="row">
      <div class="search_right">
        <el-input
          placeholder="请输入姓名/手机号"
          v-model="queryInfo.keys"
          class="input-with-select"
          style="width: 440px"
        >
          <el-button slot="append" @click="search">搜索</el-button>
        </el-input>
      </div>
      <el-button type="primary" @click="add" :disabled="addBtnDisabled">新增</el-button>
    </div>
    <div class="table">
      <el-table
        ref="contactPersonTable"
        :data="list"
        style="width: 100%"
        :header-cell-style="{ background: '#F8F9FB' }"
        @sort-change="sortChanged"
      >
        <el-table-column label="编号" min-width="100" align="center">
          <template v-slot="{ row }">
            <div>G00{{ row.id }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="liaisonsName" label="姓名" min-width="100" align="center">
        </el-table-column>
        <el-table-column prop="positionPhone" label="手机号" min-width="120" align="center">
        </el-table-column>
        <el-table-column prop="liaisonsPosition" label="职位" min-width="100" align="center">
        </el-table-column>
        <el-table-column prop="label" label="标签" min-width="100" align="center">
          <template v-slot="{ row }">
            <span :style="row.isflag?'color:#007aff':'color:#ff3b30'">{{row.label  }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="liaisonsName" label="联络人" min-width="100" align="center">
        </el-table-column>
        
        <el-table-column prop="createTime" label="添加时间" min-width="140" align="center" sortable>
        </el-table-column>
        <el-table-column label="操作" min-width="140" align="center">
          <template v-slot="{ row }">
            <div class="span_btn_list">
              <span :class="row.notFreeze ? 'color2' : 'color1'" @click="edit(row)">编辑</span>
              <span class="color3" v-if="!row.notFreeze" @click="setFreeze(row.id, row.notFreeze)"
                >冻结</span
              >
              <span class="color3" v-else @click="setFreeze(row.id)">解冻</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="new_page">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <!-- 添加联络人弹窗 -->
    <el-dialog
      :title="echo === 0 ? '新增联络人' : '编辑联络人'"
      :visible.sync="addDialogVisible"
      @closed="addDialogClosed"
      :close-on-click-modal="false"
      width="700px"
    >
      <div class="dialog_box">
        <el-form ref="form" :model="form" :rules="formruls" label-width="100px">
          <el-form-item label="姓名：" prop="liaisonsName">
            <div class="input_box">
              <el-input v-model="form.liaisonsName" placeholder="请输入姓名"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="手机号：" prop="positionPhone">
            <div class="input_box">
              <el-input v-model="form.positionPhone" placeholder="请输入手机号"></el-input>
            </div>
          </el-form-item>
          <!-- <el-form-item label="职位：" prop="liaisonsPosition">
            <div class="input_box">
              <el-input v-model="form.liaisonsPosition"></el-input>
            </div>
          </el-form-item> -->
          <el-form-item label="职位：" prop="liaisonsPosition">
            <el-radio-group v-model="form.liaisonsPosition">
              <el-radio label="企业领导人">企业领导人</el-radio>
              <el-radio label="IT负责人">IT负责人</el-radio>
              <el-radio label="业务负责人">业务负责人</el-radio>
              <el-radio label="生产负责人">生产负责人</el-radio>
              <el-radio label="其他">其他</el-radio>
            </el-radio-group>
            <div v-if="form.liaisonsPosition == '其他'">
              <el-input
                style="width: 220px"
                placeholder="请输入职位名称"
                v-model="form.liaisons"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item v-if="form.liaisonsPosition == '其他'" label="领导岗位：" prop="leadershipTeam">
            <el-radio-group v-model="form.leadershipTeam">
              <el-radio :label="true">是</el-radio>
              <el-radio :label="false">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="标签：" prop="label">
            <el-radio-group v-model="form.label">
              <el-radio label="决策链">决策链</el-radio>
              <el-radio label="影响者">影响者</el-radio>
            </el-radio-group>
          </el-form-item>
          <div v-if="form.label == '影响者'">
            <el-form-item label="分类：" prop="islabel">
              <el-radio-group style="line-height: 30px; margin-top: 10px" v-model="form.islabel">
                <el-radio label="中立者">中立者</el-radio>
                <el-radio label="支持者">支持者</el-radio>
                <el-radio label="反对者">反对者</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
          <div v-if="form.label == '决策链'">
            <el-form-item label="分类：" prop="islabel">
              <el-radio-group style="line-height: 30px; margin-top: 10px" v-model="form.islabel">
                <el-radio label="经济决策者">经济决策者</el-radio>
                <el-radio label="技术决策者">技术决策者</el-radio>
                <el-radio label="财务决策者">财务决策者</el-radio>
                <el-radio label="采购决策者">采购决策者</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div class="dialog_footer">
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="save" :disabled="saveBtnDisabled">保存</el-button>
          <el-button @click="addDialogClosed">取 消</el-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  searchContactPerson,
  searchContactPersonById,
  notFreezeContactPerson,
  saveContactPerson,
  wxCompanysave,
  updateContactPerson
} from '@/api/enterpriseCustomer.js'

const defaultQueryInfo = Object.freeze({
  companyId: 0, // 企业客户编号
  pageNum: 1,
  pageSize: 10,
  orders: 1, // 默认传1 2->时间升序;3->时间降序
  keys: '' // 姓名/手机号
})
const defaultForm = Object.freeze({
  companyId: 0, // 企业客户编号
  liaisonsName: '', // 联络人姓名
  liaisonsPosition: '', // 联络人职位
  positionPhone: '', // 联络人电话
  leadershipTeam: '',
  label: ''
})

export default {
  name: 'ContactPerson',
  components: {},
  props: {
    id: {
      type: Number
    }
  },
  watch: {
    id: {
      handler(val) {
        if (val) {
          console.log(val)
          this.queryInfo.companyId = val
          this.form.companyId = val
          this.search()
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      count: {
        setFreeze: 0
      },
      formruls: {
        liaisonsName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        liaisonsPosition: [{ required: true, message: '请选择职位', trigger: 'change' }],
        positionPhone: [{ required: true, message: '请输入手机号', trigger: 'blur' }]
      },
      addBtnDisabled: false,
      saveBtnDisabled: false,
      echo: 0, // 0是新增，1是编辑
      form: { ...defaultForm }, //表单
      addDialogVisible: false, // 添加联络人弹窗
      queryInfo: { ...defaultQueryInfo }, // 联络人查询一览
      list: [], // 联络人列表
      total: 0 // 联络人总条数
    }
  },
  created() {
    // this.queryInfo.companyId = this.id
  },
  methods: {
    async search() {
      const res = await searchContactPerson(this.queryInfo)
      if (res.resultCode === 200) {
        this.list = res.data.list
        this.list.forEach(el=>{
          if(el.label){
            el.label = el.label.replace(/,/g, "——");
           if( el.label.indexOf("影响者") != -1){
            this.$set(el,'isflag',true)
           }else{
            this.$set(el,'isflag',false)
           }
           
          }
        })
       
        this.total = res.data.total
      } else {
        this.$message.warning('获取联络人信息失败!')
      }
    },
    sortChanged(val) {
      if (val.order === 'ascending') {
        this.queryInfo.orders = 2
        this.search()
      } else {
        this.queryInfo.orders = 3
        this.search()
      }
    },
    //监听pagesize变化
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.search()
    },
    //监听页码变化
    handleCurrentChange(newCurrent) {
      this.queryInfo.pageNum = newCurrent
      this.search()
    },
    // 新增
    add() {
      this.addBtnDisabled = true
      this.echo = 0
      this.form = { ...defaultForm }
      this.addDialogVisible = true
    },
    // 编辑
    async edit(row) {
      if (row.notFreeze) {
        if (this.count.setFreeze === 0) {
          this.count.setFreeze = 1
          this.$message.warning('此用户已被冻结!')
        }
        setTimeout(() => {
          this.count.setFreeze = 0
        }, 5000)
      } else {
        const id = row.id
        const res = await searchContactPersonById({ id })
        if (res.resultCode === 200) {
          this.echo = 1
          this.form = res.data
          if (this.form.label) {
            let list = this.form.label.split(',')
            this.form.label = list[0]
            this.$set(this.form, 'islabel', list[1])
          }
          if(this.form.liaisonsPosition){
            if(this.form.liaisonsPosition.indexOf(",") != -1){
                let list=this.form.liaisonsPosition.split(',')
                this.form.liaisonsPosition=list[0]
                this.$set(this.form,'liaisons',list[1])
              }
          }
          this.addDialogVisible = true
        } else {
          this.$message.warning('获取联络人信息失败!')
        }
      }
    },
    async save() {
     
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.saveBtnDisabled = true

          // this.form.companyId = this.id
          // this.form.label = this.form.label + ',' + this.form.islabel
          // if (this.form.liaisonsPosition == '其他') {
          //   this.form.liaisonsPosition = this.form.liaisonsPosition + ',' + this.form.liaisons
          // }
          let query = {
            companyId: this.id, // 企业客户编号
            liaisonsName: this.form.liaisonsName, // 联络人姓名
            liaisonsPosition: '', // 联络人职位
            positionPhone: this.form.positionPhone, // 联络人电话
            leadershipTeam: this.form.leadershipTeam,
            label: ''
          }
          if (this.form.label) {
            query.label = this.form.label + ',' + this.form.islabel
          }
          if (this.form.liaisonsPosition == '其他') {
            query.liaisonsPosition = this.form.liaisonsPosition + ',' + this.form.liaisons
          } else {
            query.liaisonsPosition = this.form.liaisonsPosition
          }

          if (this.echo === 0) {
            const res = await saveContactPerson([query])
            if (res.resultCode === 200) {
              this.saveBtnDisabled = false

              this.addDialogClosed()
              this.$message.success('保存成功!')
              this.$emit('childMethods')
            } else {
              this.saveBtnDisabled = false
              this.$message.warning('保存失败!')
            }
          } else {
            this.$set(query,'id',this.form.id)
            const res = await updateContactPerson([query])
            if (res.resultCode === 200) {
              this.saveBtnDisabled = false

              this.addDialogClosed()
              this.$message.success('保存成功!')
              this.$emit('childMethods')
            } else {
              this.saveBtnDisabled = false
              this.$message.warning('保存失败!')
            }
          }
        }
      })
    },

    addDialogClosed() {
      this.addDialogVisible = false
      this.saveBtnDisabled = false
      this.addBtnDisabled = false
      this.search()
    },
    // 冻结、解冻
    async setFreeze(id, bol) {
      let val = `${bol ? '解冻' : '冻结'}`
      const res = await notFreezeContactPerson({ id })
      if (res.resultCode === 200) {
        this.$message.success(val + '成功!')
        this.search()
      } else {
        this.$message.warning(val + '失败!')
      }
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-radio-group {
  display: inline-block;
  line-height: 1;
  vertical-align: middle;
  font-size: 0;
  line-height: 25px;
  margin-top: 10px;
}
.contact_person {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: calc(100vh - 340px);
  padding: 20px 30px 10px 30px;
  box-sizing: border-box;
  background-color: #fff;
  .table {
    height: calc(100vh - 390px);
    overflow-y: auto;
    margin-top: 10px;
    .span_btn_list {
      span {
        cursor: pointer;
        margin-right: 10px;
      }
    }
  }
}
.input_box {
  width: 80%;
}
.dialog_footer {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #e1e1e1;
  padding: 20px;
}
.new_page {
  margin: 10px auto;
  width: 100%;
  text-align: center;
}
.search_right {
  // width: 560px;
  margin-right: 20px;
  /deep/ .el-button {
    border: 1px solid #448aff;
    color: #fff;
    border-radius: 0;
    background-color: #448aff;
  }
}
.row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.color1 {
  color: #448aff;
}
.color2 {
  color: #9b9a9a;
}
.color3 {
  color: #fd5469;
}
</style>
