<template>
  <div class="sceneinformation">
    <div class="item_box">
      <div class="item_flex">
        <div class="item" v-for="(item, index) in tablist" :key="index">
          <span class="block_back" :style="item.color"></span>
          <span>{{ item.name }}</span>
        </div>
      </div>
      <div class="right_time">
        <!-- <span class="lastUpdateTime">最近更新时间{{ lastUpdateTime }}</span> -->
      </div>
    </div>
    <div class="sceneinformation_flex">
      <org-tree
        class="org_tree_div"
        id="orgTreeNode"
        :data="data"
        :horizontal="horizontal"
        collapsable
        :render-content="renderContent"
        @on-expand="onExpand"
        @on-node-mouseout="onMouseout"
        @on-expand-mouseout="Mouseout"
        @on-node-click="getDicType"
      >
        <!-- 
        @on-node-mouseover="onMouseover"
        @on-expand-mouseover="Mouseover" -->
      </org-tree>
    </div>
    <div class="bottom_box">
      <el-button @click="goBack" type="info" plain size="small">返回</el-button>
    </div>
  </div>
</template>
    
    <script>
import OrgTree from '@/components/org-tree'
import { getSceneMapResult } from '@/api/enterpriseCustomer.js'

export default {
  components: {
    OrgTree
  },
  props: {
    companyFullName: {
      type: String,
      default: ''
    }
  },

  directives: {
    focus: {
      inserted: function (el) {
        el.querySelector('input').focus()
      }
    }
  },
  data() {
    return {
      horizontal: true,
      collapsable: false,
      lastUpdateTime: '',
      data: {
        mindMap: '',
        expand: true,
        children: []
      },
      tablist: [
        {
          name: "企业行为",
          color: "background:#448AFF",
        },
        {
          name: "应用场景",
          color: "background:#01bb7a",
        },
        {
          name: "工艺工序",
          color: "background:#2aadb5",
        },
        {
          name: "关联软件",
          color: "background:#7e70ff",
        },
        {
          name: "关联硬件",
          color: "background:#FF7D18",
        },
      ],
    }
  },
  created() {
    let companyFullName= this.$route.query.companyFullName
    const a = this.numFormatter(9999999999)
    this.getSceneMapResult(companyFullName)
  },
  methods: {
    // 返回上级
    goBack() {
      this.$router.go(-1)
    },
    //获取树形结构并处理
    //当child为null时处理
    //如果是children字段的话，只需把child改成children即可

    async getSceneMapResult(companyFullName) {
      const query = {
        companyFullName
      }
      const res = await getSceneMapResult(query)
      if (res.resultCode) {
        this.lastUpdateTime = res.data.lastUpdateTime
        ;(this.data.mindMap = this.companyFullName),
          (this.data.enterpriseBehaviorNum = res.data.children.length)
        this.data.children = res.data.children
        this.data.children.forEach((el) => {
          if (el.children) {
            el.scenarioNum = el.children.length
          }
        })
    
        
        this.toggleExpand(this.data.children, true) // 调用全部展开
        // this.filterTree(res.data.children)
      }
    },
    renderContent(h, data) {
      if (data.enterpriseBehaviorNum) {
        return (
          <div class="ReNode_comay">
            <span class="left_name">{data.mindMap}</span>
            <div class="po_right2">
              <span class="po_back"></span>
              <span>企业行为({data.enterpriseBehaviorNum})</span>
            </div>
          </div>
        )
      } else if (data.type == 2 && data.productType == '软件类型') {
        return (
          <div class="ReNodes">
            <div class="po_right1">
              <span class="po_back back4"></span>
              <span>关联软件</span>
            </div>
            <span class="left_name">{data.mindMap}</span>
          </div>
        )
      } else if (data.type == 2 && data.productType == '硬件类型') {
        return (
          <div class="ReNodes back2">
            <div class="po_right1">
              <span class="po_back back2"></span>
              <span>关联硬件</span>
            </div>
            <span class="left_name">{data.mindMap}</span>
          </div>
        )
      } else if (data.scenarioNum) {
        return (
          <div class="ReNode">
            <span class="left_name">{data.mindMap}</span>
            <div class="po_right">
              <span class="po_back back1"></span>
              <span>应用场景({data.scenarioNum})</span>
            </div>
          </div>
        )
      } else if (data.productionProcess&&data.children) {
        return (
          <div class="ReNode">
            <span class="left_name">{data.mindMap}</span>
            <div class="po_right">
              <span class="po_back back3"></span>
              <span >工艺工序({data.children.length})</span>
            </div>
          </div>
        )
      }else if (data.behaviornum) {
        return (
          <div class="ReNode_behavior">
            <span class="left_name">{data.mindMap}</span>
           
          </div>
        );
      } else {
        return (
          <div class="ReNode">
            <span class="left_name">{data.mindMap}</span>
          </div>
        )
      }
    },
    collapse(list) {
     
      list.forEach((child) => {
        if (child.expand) {
          child.expand = false
        }
      
        
        child.children && this.collapse(child.children)
      })
    },
    // 增加千分位
    numFormatter(num) {
      let res = num
      if (num && num !== null) {
        res = num.toString().replace(/\d+/, (n) => {
          return n.replace(/(\d)(?=(\d{3})+$)/g, ($1) => {
            return $1 + ','
          })
        })
      }
      return res
    },
    onMouseout(e, data) {
      // 鼠标移出树节点
      this.isShowF = false
    },
    Mouseout() {
      // 鼠标移出缩放icon
      this.bd = true
    },
    getDicType(a, b) {
      console.log(b)
      // this.show=true
    },
    toggleExpand(data, val) {
      // 节点默认全部展开
      var _this = this
    
      if (Array.isArray(data)) {
        data.forEach(function (item) {
          _this.$set(item, 'expand', val)
          if (item.children) {
           
            _this.toggleExpand(item.children, val)
          }
          if(item.behaviorScenarioResultList){
            item.behaviorScenarioResultList.forEach(el=>{
              _this.$set(el,'behaviornum',true)
            })
            if(item.children){
              item.children= item.children.concat(item.behaviorScenarioResultList) 
            }else{
              item.children=item.behaviorScenarioResultList
            }
           
            _this.toggleExpand( item.children, val);
          }
        })
      } else {
        this.$set(data, 'expand', val)
        if (data.children) {
          _this.toggleExpand(data.children, val)
        }
      }
    },
    onExpand(e, data) {
      // 节点展开收缩
      // eslint-disable-next-line eqeqeq
      if (data.leave == 1 && data.expand == true) {
        // bugfix
        var dom = document.getElementById('ddd')
        ;(dom.style.top = '5%'), (dom.style.left = '-6%')
      }
      if ('expand' in data) {
        data.expand = !data.expand
        if (!data.expand && data.children) {
          
          this.collapse(data.children)
        }
      } else {
        this.$set(data, 'expand', true)
      }
    }
  }
}
</script>
    
    <style lang="less" scoped>
.sceneinformation {
  width: 100%;
  height: calc(100vh - 340px);
  background: #fff;

  .bottom_box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
  }
  .item_box {
    display: flex;
    justify-content: center;
    width: 100%;
    background: #ffff;
    padding: 15px 0px;
    
    .right_time {
      color: #9b9a9a;
      font-size: 16px;
      display: flex;
      align-items: center;
      .right_icon,
      .lastUpdateTime {
        margin-left: 20px;
      }
    }
  }
  .item_flex {
    display: flex;
    align-items: center;
    font-size: 16px;
    .block_back {
      width: 10px;
      height: 10px;
      border-radius: 5px;
      display: inline-block;
      margin-right: 3px;
      margin-bottom: 1px;
    }
    .item {
      margin: 0px 5px;
    }
  }

  .sceneinformation_flex {
    width: 100%;
    height: calc(100vh - 370px);
    overflow: auto;
  }
  .org_tree_div {
    font-size: 16px;
  }
}

::v-deep .ReNode_comay{
  display: flex;
  align-items: center;
  position: relative;
  padding: 20px 10px;
   border-radius: 30px;
   background: #448AFF;
   white-space: nowrap;
}
::v-deep .ReNode_behavior{
  display: flex;
  align-items: center;
  position: relative;
  padding:  10px;
  background: #01bb7a;
  border-radius: 4px;
}
::v-deep .left_name{
  white-space: nowrap;
  font-feature-settings: 'zero' on;
}
::v-deep .ReNode {
  display: flex;
  align-items: center;
  position: relative;
  padding:  10px;
   border-radius: 20px;
   white-space: nowrap;
   
}
::v-deep .ReNodes {
  display: flex;
  align-items: center;
  position: relative;
  padding:  10px;
  background: #7e70ff;
  border-radius: 4px;
  white-space: nowrap;
}
::v-deep .po_right {
  position: absolute;
  right: -80px;
  top: -3px;
  color: #333;
  font-size: 12px;
  background: #fff !important;
  z-index: 9999999 !important;
}
::v-deep .po_right2 {
  position: absolute;
  right: -80px;
  top: 4px;
  color: #333;
  font-size: 12px;
  background: #fff !important;
  z-index: 9999999 !important;
}
::v-deep .po_right1 {
  position: absolute;
  left: -70px;
  width: 60px;
  top: -3px;
  color: #333;
  font-size: 12px;
  background: #fff !important;
}
::v-deep .po_back {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background: #4e93fb;
}
::v-deep .back1 {
  background: #01bb7a;
}
::v-deep .back2 {
  background: #ff9039;
}
::v-deep .back3 {
  background: #2aadb5;
}
::v-deep .back4 {
  background: #7e70ff;
}
</style>
    