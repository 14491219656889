import request from "@/utils/request"

//案例数据一览
export function selectPlanPage(data) {
  return request({
    url: '/ipadFirstPage/selectPlanPage',
    method: "post",
    data
  })
}
// 案例详情
export function getPlanDetail(query) {
  return request({
    url: "/ipadFirstPage/getPlanDetail",
    method: "get",
    params: query
  });
}

// 案例数据修改
export function updatePlan(data) {
  return request({
    url: "/ipadFirstPage/updatePlan",
    method: "post",
    data
  });
}
